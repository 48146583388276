import { container } from "assets/jss/material-kit-react";

const donationWallStyle = {
  container,
  title: {
    fontFamily: '"chelsea market", fantasy',
    paddingTop: "20px",
  },
  subtitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "1.2rem",
    marginBottom: "1rem",
  },
  root: {
    marginBottom: "2rem",
  },
  details: {},
  content: {
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "4rem",
    background: "rgba(163, 237, 255, 0.5)",
  },
  cover: {
    width: "80px",
  },
  bestDonor: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
  },
  bestDonorName: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
  },
};

export default donationWallStyle;
