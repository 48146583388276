import React from "react";

import Avatar from "@material-ui/core/Avatar";

import MuiAlert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";

import FaceIcon from "@material-ui/icons/Face";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import styles from "assets/jss/material-kit-react/views/componentsSections/donationWallStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert {...props} />;
}

const HappyTails = ({ donors, setDonateOpenModal }) => {
  const classes = useStyles();

  if (!donors || !donors.length) {
    return null;
  }
  const [recentMostDonor, ...restDonors] = donors.sort(
    (p, n) => n.amount - p.amount
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Donations</h2>
      <div className={classes.subtitle}>
        <span>A BIG thank you to our recent donors!</span>
        <a href="#" onClick={() => setDonateOpenModal(true)}>
          Click here to DONATE
        </a>
      </div>

      <Card className={classes.root} elevation={0}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <img
              className={classes.cover}
              src="https://cdn-icons-png.flaticon.com/512/3112/3112946.png"
              title="Award"
            />
            <div className={classes.bestDonor}>
              <div className={classes.bestDonorName}>
                <FaceIcon fontSize="large" />
                <Typography component="h4" variant="h4">
                  {recentMostDonor.name}
                </Typography>
              </div>
              <Typography variant="h6" color="textSecondary">
                ${recentMostDonor.amount}
              </Typography>
            </div>
            <img
              className={classes.cover}
              src="https://cdn-icons-png.flaticon.com/512/3112/3112946.png"
              title="Award"
            />
          </CardContent>
        </div>
      </Card>

      <Grid container spacing={3}>
        {restDonors.map((donor, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Alert
              className={classes.donor}
              icon={
                <Avatar alt={donor.name} className={classes.donorAvatar}>
                  {donor.name.charAt(0)}
                </Avatar>
              }
            >
              <AlertTitle>{donor.name}</AlertTitle>
              <p>${donor.amount}</p>
            </Alert>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HappyTails;
