import React from "react";
// loader video
import passionCartoonLogo from "assets/img/p4p-cartoon-small.png";

const style = {
  height: "45px",
  alignSelf: "center",
  fill: "currentColor",
};

export const PassionLogoCartoon = () => {
  return (
    <img
      src={passionCartoonLogo}
      height="65px"
      alt="passion-for-pitties-logo"
    />
  );
};

export const PassionLogo = (props) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    data-bbox="34 22 132 156"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="34 22 132 156"
    role="presentation"
    aria-hidden="true"
    fill={props.color}
    style={style}
  >
    <g>
      <path d="M163.3 22.1c-19.3.4-28.5 12.1-32.7 21.9-3.2 7.3-4.1 14.4-4.4 18.2H73.8C72.7 47.9 68 37.1 60 30.2 49.4 21 36.9 22 36.4 22.1l-2.4.2v130.3c0 7.5 2.3 13.6 7 18 7.2 6.8 17.7 7.4 21.1 7.4h74.1c19.4 0 29.8-15.6 29.8-26.9V22l-2.7.1zm-2.6 129c0 9-8.5 21.5-24.5 21.5h-33.7v-6.2c8.6-5.1 14.2-12.6 13.9-19-.2-3.5-2.1-6.4-5.4-7.8-5-2.1-8.7-.7-11 1.3-2.4-2.2-6-3.4-10.8-1.3-3.4 1.4-5.4 4.4-5.5 8-.3 6.5 5 13.7 13.5 18.7v6.2H62.8c-.1 0-11.4.6-18.3-6-3.5-3.3-5.3-8.1-5.3-14.1v-125c3.7.2 11 1.4 17.3 6.9C63.9 40.6 68 50.9 68.7 65l.1 2.5h62.5v-2.7c0-.1 0-9.4 4.1-18.7 4.9-11.3 13.4-17.5 25.3-18.6v123.6zm-60.9 10.7c-7.7-4.5-11-10.3-10.8-13.9.1-1.6.8-2.7 2.3-3.4.5-.2 1.5-.7 2.6-.7 1.3 0 2.6.6 3.5 2.7l2.4 5.6 2.4-5.6c.5-1.2 2.2-3.8 6.5-2 1.4.6 2.1 1.6 2.2 3.1.3 3.8-3.5 9.8-11.1 14.2z"></path>
      <path d="M66.8 130.9a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"></path>
      <path d="M118.6 130.9c0 10.8 8.8 19.6 19.5 19.6 10.8 0 19.5-8.8 19.5-19.6s-8.8-19.6-19.5-19.6-19.5 8.8-19.5 19.6zm24.5 0c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.2 5 5z"></path>
    </g>
  </svg>
);

export default PassionLogoCartoon;
