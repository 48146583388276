import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Pets from "@material-ui/icons/Pets";
import Favorite from "@material-ui/icons/Favorite";

// core components
// react component for creating beautiful carousel
import Carousel from "react-slick";
import Slide from "@material-ui/core/Slide";
import Card from "components/Card/Card.js";
import NavPills from "components/NavPills/NavPills.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import modalStyles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";
import carouselStyles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import Badge from "components/Badge/Badge";
import { OpenInNew } from "@material-ui/icons";

const useModalStyles = makeStyles(modalStyles);
const useCarouselStyles = makeStyles(carouselStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const rainbowColors = [
  undefined,
  "primary",
  "info",
  "success",
  "warning",
  "danger",
  "rose",
];
const randomColor = () =>
  rainbowColors[Math.floor(Math.random() * rainbowColors.length)];

const DogImages = ({ images = [] }) => {
  const classes = useCarouselStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    maxWidth: "md",
    className: "center",
    centerMode: true,
  };
  return (
    images.length && (
      <div className={classes.section}>
        <div className={classes.container}>
          <Card carousel>
            <Carousel className={classes.slickImage} {...settings}>
              {images.map((e, i) => (
                <div key={i}>
                  <img src={e} alt="slide" className="slick-image" />
                </div>
              ))}
            </Carousel>
          </Card>
        </div>
      </div>
    )
  );
};

export const ModalContent = ({ dog, ctx, formUrl }) => {
  const { description, photos = [], attributes = {}, tags = [] } = dog;
  const classes = useModalStyles();

  const displayAttributes = [
    ...tags,
    ...Object.entries(attributes)
      .map(([key, val]) => val && key)
      .filter((e) => e),
  ];

  const images = photos.map((e) => e?.large).sort(() => 0.5 - Math.random());
  return (
    <NavPills
      color="primary"
      tabs={[
        {
          tabButton: "Profile",
          tabIcon: Pets,
          tabContent: (
            <span>
              <p
                className={classes.content}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {displayAttributes.length && (
                <p className={classes.content}>
                  Attributes:{" "}
                  {displayAttributes.map((e, i) => (
                    <Badge key={i} color={randomColor()}>
                      {e}
                    </Badge>
                  ))}
                </p>
              )}
              <DogImages images={images} />
            </span>
          ),
        },
        {
          tabButton: `Apply to ${ctx}`,
          tabIcon: Favorite,
          onClick: () => window.open(formUrl, "_blank"),
          // tabContent: (
          //   <span className={classes.adoptFrameHolder}>
          //     <iframe src={formUrl} className={classes.adoptFrame} />
          //   </span>
          // ),
        },
        {
          tabButton: `More info`,
          tabIcon: OpenInNew,
          onClick: () => window.open(dog.url, "_blank"),
        },
      ]}
    />
  );
};

const DogProfileModal = ({
  setClassicModal,
  classicModal,
  dog,
  ctx,
  formUrl,
}) => {
  const { name } = dog;
  const classes = useModalStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme?.breakpoints?.down("sm"));

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={classicModal}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      keepMounted
      onClose={() => setClassicModal(false)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setClassicModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>{name}</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <ModalContent dog={dog} ctx={ctx} formUrl={formUrl} />
      </DialogContent>
    </Dialog>
  );
};

export default DogProfileModal;
