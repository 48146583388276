import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
import Scroll from "react-scroll";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import Favorite from "@material-ui/icons/Favorite";
import AttachMoney from "@material-ui/icons/AttachMoney";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";

import PassionLogo from "components/PassionLogo.js";
import DogList from "components/Dog/DogList.js";
import Spotlight from "components/Spotlight/Spotlight.js";
import Story from "components/Story/Story.js";
import Team from "components/Team/Team.js";
import HappyTails from "components/HappyTails/HappyTails.js";
import Partners from "components/Partners/Partners.js";
import Contact from "components/Contact/Contact.js";
import Donate from "components/Donate/Donate.js";
import Events from "components/Events/Events.js";
import DonationWall from "components/Donate/DonationWall.js";

import styles from "assets/jss/material-kit-react/views/components.js";

import getContent from "network/get-content";

// loader video
import loaderVideoSrc from "assets/img/loader.gif";

const useStyles = makeStyles(styles);
const Element = Scroll.Element;
const scroller = Scroll.scroller;

const scrollToElement = (elementId) => {
  // Somewhere else, even another file
  scroller.scrollTo(elementId, {
    duration: 1500,
    delay: 100,
    smooth: true,
    offset: -100,
  });
};

const ADOPT_FORM_URL = "https://form.jotform.com/210465873231049";
const FOSTER_FORM_URL = "https://form.jotform.com/210457908950057";

export default function Components(props) {
  const classes = useStyles();
  const [fetchContent, setFetchContent] = React.useState(false);
  const [donateOpenModal, setDonateOpenModal] = React.useState(false);

  React.useEffect(async () => {
    const response = await getContent();
    setTimeout(() => setFetchContent(response), 3000);
  }, []);

  const { ...rest } = props;
  if (!fetchContent) {
    return (
      <div className={classes.loaderBg}>
        <div className={classes.loaderVideoContainer}>
          <img className={classes.loaderVideo} src={loaderVideoSrc} />
          <h3 className={classes.textCenter}>Now Loading...</h3>
        </div>
      </div>
    );
  }
  const { adopt, foster, success, team, spotlight, partners, events, donors } =
    fetchContent;
  return (
    <div>
      <Header
        Img={PassionLogo}
        brand={"Passion for Pitties"}
        subbrand={"San Diego Pitbull Rescue"}
        rightLinks={
          <HeaderLinks
            scrollToElement={scrollToElement}
            donateAction={() => setDonateOpenModal(true)}
          />
        }
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        image={
          // "https://static.wixstatic.com/media/66eb2d_9851cc4863874e8db7dfc5f393e3bf63~mv2.png"
          "https://images.pexels.com/photos/4538457/pexels-photo-4538457.jpeg?cs=srgb&dl=pexels-jennie-orh-4538457.jpg&fm=jpg"
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>
                  Saving lives, one pitbull at a time.
                </h1>
                <h3 className={classes.subtitle}>
                  Dedicated to ending the stigma against bully breeds.
                </h3>
                <Button
                  color="info"
                  round
                  onClick={() => scrollToElement("adopt-link")}
                >
                  <Favorite className={classes.icons} /> Adopt
                </Button>
                <Button
                  color="warning"
                  round
                  onClick={() => setDonateOpenModal(true)}
                >
                  <AttachMoney className={classes.icons} /> Donate
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <Donate
        donateOpenModal={donateOpenModal}
        setDonateOpenModal={setDonateOpenModal}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        {spotlight.length ? (
          <Spotlight spotlight={spotlight} formUrl={ADOPT_FORM_URL} />
        ) : null}
        <Element name="adopt-link" />
        <DogList
          ctx="adopt"
          title="Adorable Adoptables"
          animals={adopt}
          formUrl={ADOPT_FORM_URL}
        />
        <Element name="foster-link" />
        <DogList
          ctx="foster"
          title="Friendly Fosters"
          animals={foster}
          formUrl={FOSTER_FORM_URL}
        />
        <Element name="donation-wall-link" />
        <DonationWall donors={donors} setDonateOpenModal={setDonateOpenModal} />
        <Element name="events-link" />
        <Events events={events} />
        <Element name="story-link" />
        <Story donateAction={() => setDonateOpenModal(true)} />
        <Element name="team-link" />
        <Team team={team} />
        <Element name="happy-tails-link" />
        <HappyTails imgs={success} />
        <Element name="partners-link" />
        <Partners partners={partners} />
        <Element name="contact-link" />
        <Contact />
        {/* <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer />
    </div>
  );
}
